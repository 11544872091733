import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

export default function ServicesBox1() {
  return (
    <Card sx={{ minWidth: 275, minHeight: 330 }}>
      <CardContent>
        <Typography sx={{ fontWeight: 'bold', fontSize: 30, textAlign: 'center', color: 'black' }} color="text.secondary" gutterBottom>
        Providing Care
        </Typography>
        <Typography  sx={{textAlign: 'center', fontSize: 15}}  variant="h5" component="div">
          
         <p className='profile-p'> We provide care that’s more human, better tailored to the individual and available all the time.
         </p>
         <p>
        Providing specialised help depending on individual's clinical care needs.
        </p>
        <p>Our team of qualified staff do this safely and in a personal way at the patient's home.</p>
        </Typography>
      </CardContent>
    </Card>
  );
}