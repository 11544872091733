import React from 'react';
import ContactBox1 from './ContactBox1';
import ContactBox2 from './ContactBox2';
import ContactMsgBox from './ContactMsgBox';
import { Grid, CardContent, Typography } from '@mui/material';

function Contact() {
  return (
    <div id='contact'>
      <Grid>
        <Grid>
          <CardContent >
            <Typography sx={{
              marginTop: 2,
              fontSize: 30,
              color: '#3f04ac',
              textAlign: 'center',
              fontWeight: 'bold'
            }}
            >
              Contact us
            </Typography>
          </CardContent>
        </Grid>
      </Grid>
      <Grid sx={{
        flexGrow: 1,
        alignItems: 'center',
        bgcolor: '#f7f0f0',
        overflow: 'hidden',
        fontWeight: 'bold',
      }} container spacing={4} justify='centre'>
        <Grid item xs={12} sm={6} md={6}>
          <ContactBox1 />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <ContactBox2 />
        </Grid>
      </Grid>
      <Grid sx={{
        height: 'fit',
        alignItems: 'center',
        bgcolor: '#f7f0f0',
        overflow: 'hidden',
        fontWeight: 'bold'
      }} container spacing={4} justify='centre'>
        <Grid item xs={12} sm={6} md={6}>
          <ContactMsgBox />
        </Grid>
      </Grid>
    </div>
  )
}
export default Contact;