import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';


export default function MissionStatement() {
  return (
    <Card sx={{ minWidth: 275, minHeight: 275 }}>
      <CardContent>
        <Typography sx={{ fontSize: 30, 
          color: 'black', 
          textAlign: 'center', 
          fontWeight: 'bold' }} 
          color="text.secondary" 
          gutterBottom>
         Mission Statement
        </Typography>
        <Typography
        sx={{alignItems: 'center', textAlign: 'center'}} 
        fontSize={15}>
          
          <p className='profile-p'>
          To provide the best possible service with a friendly, caring and compassionate approach.</p>  
          <p>To assist clients to maintain their independence and quality of life in their own home.</p>
          <p>To provide appropriate trained and skilled staff that fit our clients' needs.
of our clients.</p>
        </Typography>
      </CardContent>
    </Card>
  );
}