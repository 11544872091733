import React from 'react';

import ServicesBox1 from './ServicesBox1';
import { Grid, CardContent, Typography } from '@mui/material';
import ServicesBox2 from './ServiceBox2';

function Services (){
    return(
        <div id='services'>
            <Grid>
              <Grid>
                <CardContent >
                  <Typography  sx={{ marginTop: 0, 
                  fontSize: 30, 
                  color: '#3f04ac', 
                  textAlign: 'center', 
                  fontWeight: 'bold' }} 
                  >
                  Our services
                  </Typography>
                </CardContent>
          </Grid>
      </Grid>
      <Grid sx={{
        display: 'flex',
        alignItems: 'center',
        bgcolor: '#f7f0f0',
        overflow: 'hidden',
        boxShadow: 1,
        fontWeight: 'bold',
      }} container spacing={4} justify='centre'>
            <Grid item sx={12} sm={6} md={6}> 
            <ServicesBox1/>
            </Grid>
            <Grid item sx={12} sm={6} md={6}>
            <ServicesBox2/>
            </Grid>
        </Grid>
        </div>
    )
}
export default Services;