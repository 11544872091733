import React from 'react';
import { Grid, Typography } from '@mui/material';

function Footer (){
    return(
        
        <div className='footer'>
            <Grid container
            spacing={0}
        direction="column"
        alignItems="center">
          <Grid container 
        sx={{ marginBottom: 3, marginTop: 2}}
        spacing={0}
        direction="column"
        textAlign={'center'}
        justifyContent="center">
            <Typography sx={{alignItems: 'center', 
            textAlign: 'center'}} 
        fontSize={15}>
              <Typography></Typography>
<Typography></Typography>
</Typography>
        </Grid>
            </Grid>
        <Grid container
        marginTop={5}
        direction="column"
        textAlign={'center'}
        justifyContent="center">
                    <Typography sx={{alignItems: 'center',
                     textAlign: 'center'}} 
        fontSize={15}>
                &copy;{new Date().getFullYear()} PURPOSE Care Ltd. All rights reserved.
                </Typography>
                </Grid>
        </div>
        
    );

}

export default Footer;