import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';


export default function ServicesBox2() {
  return (
    <Card sx={{ minWidth: 275, minHeight: 330 }}>
      <CardContent>
        <Typography sx={{ fontWeight: 'bold', fontSize: 30, textAlign: 'center', color: 'black' }} color="text.secondary" gutterBottom>
        Our Team of Staff
        </Typography>
        <Typography sx={{fontSize: 15, textAlign: 'center'}} variant="h5" component="div">          
        <p className='profile-p'>A care home is an important place and we understand that.  
        </p>
        <p>        That is why we mantain a team of staff that is compassionate, dedicated and diversified in terms of culture and skills.</p>
        
        <p>        Our staff provide high standard care.
          </p>
        </Typography>
      </CardContent>
    </Card>
  );
}