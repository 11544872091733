import * as React from 'react';
import { Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import DownloadIcon from '@mui/icons-material/Download';

const APPLICATION_PDF_FILE_URL = '/downloads/Purpose_Application_Form_2023.pdf';

export default function ContactBox2() {
    const downloadFileAtURL=(url)=>{
        const fileName = url.split('/').pop();
          const aTag = document.createElement('a');
          aTag.href = url;
          aTag.setAttribute('download', fileName);
          document.body.appendChild(aTag);
          aTag.click();
          aTag.remove();
      };
  return (
    <Card sx={{ minWidth: 275, minHeight: 375 }}>
      <CardContent>
        <Typography sx={{marginBottom: 5, textAlign: 'center', 
        fontSize: 30, color: 'black', fontWeight: 'bold' }} 
        gutterBottom>
        Downloads
        </Typography>
        <Grid container 
        direction="row"
        justifyContent="center">
          <button class='btnDownload' onClick={()=>{downloadFileAtURL(APPLICATION_PDF_FILE_URL)}}>
                    <DownloadIcon/>
                    Application form
          </button>
        </Grid>
        <br/>
      </CardContent>
    </Card>
  );
}