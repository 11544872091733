import React from 'react';
import TopNav from './components/NavTop';
import Header from './components/Header';
import About from './components/About';
import Services from './components/Services';
import Contact from './components/Contact';
import Footer from './components/Footer';
import NavbarBelow from './components/NavbarBelow';
import './App.css';
function App() {
  return (
    <div>
      <div>
      <TopNav/>
      </div>
      <NavbarBelow/>
      <div>
      <Header/>
      </div>
      <div className='content'>
      <About/>
      <Services/>
      <Contact/>
      </div>
      <Footer/>
    </div>
  );
}

export default App;
