import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';


export default function VisionStatement() {
  return (
    <Card sx={{ minWidth: 275, minHeight: 275 }}>
      <CardContent>
        <Typography sx={{ fontSize: 30, 
          color: 'black', 
          textAlign: 'center', 
          fontWeight: 'bold' }} 
          color="text.secondary" 
          gutterBottom>
         Vision Statement
        </Typography>
        <Typography
        sx={{alignItems: 'center', textAlign: 'center'}} 
        fontSize={15}>
          
          <p className='profile-p'>
          We hope to be among the leading care providers around the region by providing our clients with a caring, supportive and committed care to achieve the best loving and caring outcome for them.</p>
        </Typography>
      </CardContent>
    </Card>
  );
}