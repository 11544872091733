import React from "react";
import emailjs from "@emailjs/browser";
import { useForm } from "react-hook-form";
import {
  Card, CardContent, TextField,
  Typography, Button
} from '@mui/material';
import styled from "styled-components";

const Contact = () => {
  const {
    register,
    handleSubmit,
    reset,
    trigger,
    formState: { errors }
  } = useForm({
    defaultValues: {
      user_email: "",
      message: "",
      user_name: "",
    }
  });
  const resetForm = () => {
    reset();
  };
  const sendEmail = (formData) => {
    resetForm();
    emailjs
      .send(
        "service_ebl5xtd",
        "template_sp35vml",
        formData,
        "cDU0hvWIpd0vlscCu"
      )
      .then(
        (error) => {
          console.log(error.text);
        }
      );
  };


  return (
    <Card alignItems='center'
      justifyContent='center'
      sx={{ minWidth: 275, minHeight: 375 }}>
      <Typography sx={{
        fontSize: 30,
        color: 'black',
        textAlign: 'center',
        fontWeight: 'bold'
      }}
        color="text.secondary"
        paddingTop={0}>
        Send us a message
      </Typography>

      <StyledContactForm>
        <form onSubmit={handleSubmit(sendEmail)}>
          <CardContent sx={{
            paddingBottom: '0px', alignItems: 'center'
          }}>
            <TextField sx={{ width: 300 }} label='Name' name='user_name' variant="standard"
              {...register("user_name", { required: true })} />
            {errors.user_name && (
              <span className="msgError">
                Name is required.
              </span>
            )}
          </CardContent>
          <CardContent sx={{ paddingBottom: '0px' }}>
            <TextField sx={{ width: 300 }} label="Email" name="user_email" variant="standard"
              {...register("user_email", {
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address",
                }
              })}
              onKeyUp={() => {
                trigger("user_email");
              }} />
            {errors.user_email && (
              <span className="msgError">
                {errors.user_email.message}</span>
            )}
          </CardContent>
          <CardContent sx={{ paddingBottom: '0px' }}>
            <TextField sx={{ width: 300 }}
              label="Message"
              name="message"
              multiline
              rows={4}
              {...register("message", { required: true })}
            />
            {errors.message && (
              <span className="msgError">
                Message can't be blank.
              </span>
            )}
          </CardContent>
          <Button type="submit" sx={{ width: 300, marginLeft: 2 }}
            variant="contained" color="success"
          >Send
          </Button>
        </form>
      </StyledContactForm>
    </Card>
  );
}

export default Contact;

// Styles
const StyledContactForm = styled.div`
  width: 100%;
  align-items: center;

  form {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    font-size: 16px;
    padding-top: 0;

    span {
      align: left;
      anchor: left;
      color: #FF0000;
    }

    Button [disabledBtn] {
      border: 1px solid black;
      cursor: not-allowed;
    }
  }
`;