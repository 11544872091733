import React, { useState } from 'react';
import {
    AppBar, Grid, Toolbar,
    Typography, Tabs, Tab,
    useTheme,
    useMediaQuery
} from '@mui/material';
import Logo from '../images/purposeLogoOval.png';
import DrawerComp from './Drawer';
import './NavTop.css';

const TopNav = ({ links }) => {
    const theme = useTheme();
    console.log(theme);
    const isMatch = useMediaQuery(theme.breakpoints.down("md"));
    const [value, setValue] = useState();
    return (
        <AppBar sx={{ bgcolor: '#3390FF' }}>
            <Toolbar>
                {isMatch ? <>
                    <Grid xs={{placeItems: 'centre'}} spacing={2} container>
                        <Grid item xs={4}>
                            <img className='logo' alt='logo' src={Logo} />
                        </Grid>
                        <Grid sx={{ display: 'flex', marginLeft: 0.8, alignItems: 'center'}} item xs={{ md: '6', sm: '8'}}>
                            <Typography className='titleClass' sx={{ fontSize: 'large', textAlign: 'center', fontWeight: 'bold' }}>
                            PURPOSE Care Ltd.
                            </Typography>
                        </Grid>
                    </Grid>
                    <DrawerComp links={links} /></> :
                    <Grid sx={{ placeItems: "center" }} container>
                        <Grid>
                            <Typography>
                                <img src={Logo} alt='logo' className='logo' />
                            </Typography>
                        </Grid>
                        <Grid xs={6}>
                            <Tabs indicatorColor='secondary' textColor='white' value={value}
                                onChange={(e, value) => setValue(value)}>
                                <Tab className='active' href='#main' label='Home'></Tab>
                                <Tab className='about' href="#about" label='About'></Tab>
                                <Tab className='services' href="#services" label='Services'></Tab>
                                <Tab className='contact' href='#contact' label='Contact'></Tab>
                            </Tabs>
                        </Grid>
                    </Grid>
                }
            </Toolbar>
        </AppBar>
    )
};

export default TopNav;