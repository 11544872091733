import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';


export default function AboutBox2() {
  return (
    <Card sx={{ minWidth: 275, minHeight: 275 }}>
      <CardContent>
        <Typography sx={{ fontSize: 30, color: 'black', textAlign: 'center', fontWeight: 'bold' }} color="text.secondary" gutterBottom>
         All Care Settings
        </Typography>

        <Typography
        sx={{fontSize: 15, alignItems: 'center', textAlign: 'center',
      marginBottom: 4}} 
        variant="h5" component="div">
          
          <p className='profile-p'>
          We provide staff to all types of care settings.</p>
        </Typography>
        <Grid container 
        direction="column"
        alignItems="center"
        justifyContent="center"
        >
        <Typography fontSize={15}>
        Hospitals
        </Typography>
        <Typography fontSize={15}>
        Care Homes
        </Typography>
        <Typography fontSize={15}>
        Supported Living
        </Typography>

        </Grid>
      </CardContent>
    </Card>
  );
}