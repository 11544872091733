import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';


export default function AboutBox1() {
  return (
    <Card sx={{ minWidth: 275, minHeight: 275 }}>
      <CardContent>
        <Typography sx={{ fontSize: 30, 
          color: 'black', 
          textAlign: 'center', 
          fontWeight: 'bold' }} 
          color="text.secondary" 
          gutterBottom>
         What we do
        </Typography>
        <Typography
        sx={{alignItems: 'center', textAlign: 'center'}} 
        fontSize={15}>
          
          <p className='profile-p'>
          Our top priority is to deliver care that meets personal needs. 
          <p>In order to achieve this goal we supply healthcare providers that are well trained to deal with age related issues.</p>Understand how to cope with learning disability and autism requirements. 
          </p>
        </Typography>
        <Typography variant="body2">
        
        </Typography>
      </CardContent>
    </Card>
  );
}