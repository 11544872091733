import * as React from 'react';
import { Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';

export default function ContactBox1() {
  return (
    <Card 
    alignItems='center'
    justifyContent='center'
    sx={{ paddingBottom: 12, 
    minWidth: 275, minHeight: 280 }}>
      <CardContent>
        <Typography sx={{ color: 'black', fontWeight: 'bold', 
        textAlign: 'center', marginBottom: 5, fontSize: 30 }}
         color="text.secondary" 
         gutterBottom
         alignItems='center'
         justifyContent='center'>
        Get in touch 
        </Typography>
        <Grid container 
        sx={{ marginBottom: 2}}
        direction="column"
        alignItems="center">
        <LocalPhoneIcon className='phoneIcon'/>
        <WhatsAppIcon sx={{color: 'green'}}/>
        
            <Typography>07518609649</Typography>
        
        <br/>
          <EmailIcon color='primary'/>
            <Typography>info@purposerecruit.co.uk</Typography>
            </Grid>
        
      </CardContent>
    </Card>
  );
}