import React from 'react';

import AboutBox1 from './AboutBox1';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import AboutBox2 from './AboutBox2';
import MissionStatement from './MissionStatement';
import Values from './Values';
import VisionStatement from './VisionStatement';
import Objectives from './Objectives';

function About (){
    return(
        <div id='about' className='s-heading'>
            <Grid>
              <Card>
                <CardContent>
                  <Typography sx={{ marginTop: 6, fontSize: 30, color: '#3f04ac', textAlign: 'center', fontWeight: 'bold' }} gutterBottom>
                    About Us
                  </Typography>
                  <Typography sx={{alignItems: 'center', textAlign: 'center', paddingBottom: 1}}>
          <p>PURPOSE Care Ltd is a recruitment agency that specialises in recruiting and supplying highly qualified, experienced and vetted healthcare professions.</p>
          <p>The purpose of PURPOSE Care Ltd is to offer flexible recruitment support Specialising in Health & Social Care and Care Support Workers.
          </p></Typography>
                </CardContent>
          </Card>
      </Grid>
      <Grid sx={{
        
        bgcolor: '#f7f0f0',
        overflow: 'hidden',
        fontWeight: 'bold',
      }} 
      container spacing={4} justify='centre'>
            <Grid sx={{flexDirection: 'row'}} item xs={12} sm={6} md={6}> 
            <AboutBox1/>
            </Grid>
            <Grid sx={{flexDirection: 'row', flex: 1}}  item xs={12} sm={6} md={6}>
            <AboutBox2/>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
            <MissionStatement/>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Values/>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <VisionStatement/>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Objectives/>
            </Grid>
        </Grid>
        </div>
    );
}
export default About;