import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';


export default function Objectives() {
  return (
    <Card sx={{ minWidth: 275, minHeight: 275 }}>
      <CardContent>
        <Typography sx={{ fontSize: 30, 
          color: 'black', 
          textAlign: 'center', 
          fontWeight: 'bold' }} 
          color="text.secondary" 
          gutterBottom>
         Objectives Statement
        </Typography>
        <Typography
        sx={{alignItems: 'center', textAlign: 'center'}} 
        fontSize={15}>
          
          <p className='profile-p'>
          To offer a consistent, unconditional care and enabling each service user to live in an environment that encourages mutual respect, positive relationships, trust, and consideration for others.</p>          
        </Typography>
      </CardContent>
    </Card>
  );
}