import React from 'react';

function Header () {
    return(
        <div id='mainimg'>
            <div className='name'>
                <h1><span>PURPOSE Care</span></h1>
                <p className='details'>We recruit for nursing, residential, mental health, learning disability and home settings.</p>
            </div>
        </div>
    );
}

export default Header;