import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const Semicolon = (
  <Box
    component="span"
    sx={{ display: 'inline-block', ml: '5px', transform: 'scale(1.5)' }}
  >
    :
  </Box>
);

const StyledHeading = (
  <Typography
    component="span"
    sx={{ display: 'inline-block', transform: 'scale(1)', fontStyle: 'italic', fontSize: '30', fontWeight: 'bold' }}
  >
    Respect and Dignity
    </Typography>
);
const CommitmentToQualityOfCare = (
  <Typography
    component="span"
    sx={{ display: 'inline-block', transform: 'scale(1)', fontStyle: 'italic', fontSize: '30', fontWeight: 'bold' }}
  >
    Commitment to quality of care
    </Typography>
);

export default function Values() {
  return (
    <Card sx={{ minWidth: 275, height: 475 }}>
      <CardContent>
        <Typography sx={{ fontSize: 30, 
          color: 'black', 
          textAlign: 'center', 
          fontWeight: 'bold' }} 
          color="text.secondary" 
          gutterBottom>
         Values
        </Typography>
        <Typography
        sx={{alignItems: 'center', textAlign: 'center'}} 
        fontSize={15}>
          <p className='profile-p'>{StyledHeading}{Semicolon} We are committed to building the loyalty of our clients and ensuring that the satisfaction of their needs is paramount to everything that we do.</p>
           <p>We respect their aspirations and commitments and seek to understand and promote their priorities, needs, abilities and limits. </p>
           <p>When providing care, we treat our clients with dignity, we aim to support and empower them and to respect their right to make their own decisions.</p>
           <p>{CommitmentToQualityOfCare}{Semicolon} We believe that we can only provide the best possible care by recruiting training and retaining the best staff who share PURPOSE Care’s values and who are committed to PURPOSE Care’s vision.</p>        
        </Typography>
      </CardContent>
    </Card>
  );
}